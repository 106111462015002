import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import Loading from './Loading';
import toast, { Toaster } from 'react-hot-toast';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import BackButton from './BackButton';
export default function Withdraw() {


    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    const inputEls = useRef([]);
    const [isLoading, setIsLoading] = useState(false);
    const [bankdetail, setBankDetail] = useState({
        mobileNo: "",
        accountNo: "",
        fullName: "",
        ifsc: ""
    })
    const [walletAmount, setwalletAmount] = useState(0);
    const [token, setToken] = useState("");
    const [allValues, setAllValues] = useState({
        WithdrawAmount: '',
        Password: ''
    });
    const changeHandler = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        GetWalletAmount();
    }, []);
    const GetWalletAmount = () => {
        setIsLoading(true);
        var param = new Object();
        param.MemberId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.ActionMode = "GetWalletAmount";
        var obj = new Object();
        obj.procName = 'SenderRegistration';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setwalletAmount(res[0].WalletAmount);
            setToken(res[0].Token);
            //Setting Bank Details
            setBankDetail({ mobileNo: res[0].MobileNo, accountNo: res[0].AccountNo, fullName: res[0].AccountHolderName, ifsc: res[0].IFSC });
            if (res[0].AccountNo == "NA") {
                toast.error('Please Bind Your Bank Details.');
                routeChange('bindbank');
            }
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }
    const ProceedToWithdraw = () => {
        if ((parseFloat(allValues.WithdrawAmount) || 0) == 0) {
            toast.error('Enter Withdraw Amount.');
            inputEls.current[1].focus();
            return;
        }
        if (allValues.Password == "") {
            toast.error('Enter Transaction Password.');
            inputEls.current[2].focus();
            return;
        }
        setIsLoading(true);
        var Obj = new Object();
        Obj.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        Obj.WalletType = "CommissionWallet";
        Obj.WithdrawAmount = allValues.WithdrawAmount;
        Obj.LoginPassword = allValues.Password;
        Obj.Token = token;
        var objWithdraw = new Object();
        objWithdraw.procName = 'GetOnlineWIthdrawAmountByWalletType';
        objWithdraw.Para = JSON.stringify(Obj);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: objWithdraw
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            if (res[0].StatusCode == "1") {
                GetWalletAmount();
                toast.success(res[0].Msg);
                inputEls.current[1].value = "";
            }
            else {
                if (res[0].StatusCode == "0") {
                    toast.error(res[0].Msg);
                } else {
                    toast.error('Something went wrong, plz try again later');
                }
            }
        }).catch(function (error) {
            setIsLoading(false);
        });
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Withdraw</title>
                <link rel="stylesheet" href="css/Recharge.css" />
                <link rel="stylesheet" href="css/font-awesome.min.css" />
            </Helmet>
            <div><Toaster toastOptions={{
                // Define default options
                position: "top-center",
                className: '',
                duration: 5000,
                style: {
                    fontSize: '0.28em',
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff'
                }
            }} /></div>
            {isLoading ? <Loading /> : ""}
            <div class="recharge-wrap" style={{ backgroundColor: '#fff' }}>
                <div className="navbar">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>Withdraw</span>

                </div>

                <div className="list-wrap list-wrap2">
                    <div className='BalanceAmt' style={{ marginTop: '4%', borderRadius: 5 }}>
                        <div><h5 style={{ fontSize: '1.2em', marginBottom: '0px' }}>Wallet Balance</h5>
                            <div id="BalanceWithdrawnAmount" style={{ fontWeight: 'bold', color: 'blue' }} /><i className='fa fa-inr'></i>{walletAmount}</div>
                    </div>
                    <div className="rechargedetail" style={{ marginTop: 5, background: '#fff', padding: 0, borderRadius: 8 }}>
                        <div style={{ display: 'block !important' }}>
                            <div style={{ display: 'block !important' }}><div style={{ fontSize: '18px' }}>Enter Amount</div></div>
                            <div style={{ display: 'block !important' }}>
                                <input type="number" ref={(el) => (inputEls.current[1] = el)} onChange={changeHandler} className="number_only withInput" name='WithdrawAmount' id="WithdrawAmount" placeholder="Amount" style={{ width: '100%', height: '40px', fontSize: '18px' }} />
                            </div>
                        </div>
                        <div style={{ display: 'block' }}>
                            <div>
                                <div style={{ margin: 0, fontSize: 18, fontWeight: 'normal', color: '#5a5252' }}>Password:</div>
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <p style={{ margin: 0, fontSize: 14, fontWeight: 'normal', color: '#5a5252' }} id="wAccountNo"></p>
                            </div>
                        </div>


                        <div>
                            <input style={{ width: '100%', fontSize: 15, paddingLeft: 4, border: '1px solid #eee !important', borderRadius: '8px !important', height: 40 }} type="password" ref={(el) => (inputEls.current[2] = el)} onChange={changeHandler} id="Password" name="Password" className="withInput" placeholder="Login password" />
                        </div>
                    </div>

                    <div className="rechargedetail" style={{ marginTop: 5, background: '#fff', padding: 10, borderRadius: 8 }}>
                        <button onClick={ProceedToWithdraw} type="button" id="btnSubmit" style={{ margin: 0,backgroundColor:'#000' }} className="btn btn-info" >Withdraw Now</button>
                    </div>
                    <div className="withdrawalInstruction" style={{ background: '#fff', padding: 10, borderRadius: 8, marginBottom: 55 }}>
                        <div style={{ paddingBottom: 10 }}>
                            <p className="tips" style={{ fontSize: 14, color: '#000' }}>
                                <div style={{ marginBottom: '7px' }}>1.Service Charge 10%.</div>
                                <div style={{ marginBottom: '7px' }}>2.Minimum withdrawal amount is Rs 200.</div>
                                <div style={{ marginBottom: '7px' }}>3.Withdrawal time everyday from 8:00
                                    am to 8:00 pm .</div>
                                <div style={{ marginBottom: '7px' }}>4.Withdrawal is instant.</div>

                            </p>
                        </div>
                    </div>
                    <input type="hidden" id="token" />
                </div>

            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .lock {
                        font-size: 18px !important;
                        color: #000 !important;
                        position: absolute !important;
                        top: 30px !important;
                        left: 12px !important;
                        z-index: 99 !important;
                    }
           
                    .list-wrap.list-wrap2 .price div {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        height: auto;
                        -webkit-box-align: end;
                        -ms-flex-align: end;
                        align-items: flex-end;
                        font-size: .6rem;
                        -webkit-transform: translateY(-0.22rem);
                        transform: translateY(-0.22rem);
                        margin-right: 11px !important;
                    }
            
                    .list-wrap.list-wrap2 .price {
                        background: #fff;
                        height: auto !important;
                        border-radius: 0.2rem;
                        padding-left: 0.2rem;
                        margin-top: 14px;
                    }
            
                        .list-wrap.list-wrap2 .price div input {
                            width: 90%;
                            font-size: 15px;
                            padding-left: 30px;
                            border: 1px solid #eee !important;
                            border-radius: 8px !important;
                            height: 40px;
                        }
            
                    input:focus {
                        outline: none !important;
                        border-color: #719ECE;
                    }
            
                    .list-wrap .btn {
                        line-height: 42px;
                    }
            
                    .topbg {
                        height: 82px !important;
                        overflow: hidden;
                        background: #ffffff;
                        background-size: 100% auto;
                        color: #FFFFFF;
                        padding-left: 0.26rem;
                    }
            
            
            
                    .list-wrap .btn {
                        height: 0.88rem;
                        line-height: 44px;
                        background: #4D148C;
                        border-radius: 4px;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 400;
                        margin: 0.6rem 0 0.7rem 0;
                        cursor: pointer;
                        opacity: 1;
                        pointer-events: all;
                        width: 100%;
                        font-family: Microsoft YaHei !important;
                    }
            
                    .tips {
                        font-size: 10px;
    color: #000 !important;
    font-style: italic;
    font-weight:normal !important;
    margin-bottom:10px;
                    }
          `
                }}
            />
        </>
    )
}
