import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from "react-helmet";
import axios from "axios";
import Loading from './Loading';
import toast, { Toaster } from 'react-hot-toast';
import RechargePackages from './RechargePackages';
import BackButton from './BackButton';
export default function Recharge() {
    const [isLoading, setIsLoading] = useState(false);
    const [walletAmount, setwalletAmount] = useState(0);
    const [packages, setPackages] = useState([]);
    const [rechargeAmount, setRechargeAmount] = useState(0);
    const [UTRNo, setUTRNo] = useState(0);
    const [ATCNumber, setATCNumber] = useState(0);
    const [txnAmount, settxnAmount] = useState(0);
    const [token, setToken] = useState("");
    const [defaultUPI, setdefaultUPI] = useState("");
    const handleChange = (event) => {
        setRechargeAmount(event.target.value);
    }

    const handleUTRChange = (event) => {
        setUTRNo(event.target.value);
    }

    const handleTxnAmountChange = (event) => {
        settxnAmount(event.target.value);
    }
    const inputRefAmount = useRef();
    const inputRefUTR = useRef();
    const inputRefTxnAmount = useRef();
    useEffect(() => {
        BindPackage();
        GetWalletAmount();
    }, []);
    const BindPackage = () => {
        setIsLoading(true);
        var param = new Object();
        param.ActionMode = "GetPackageRecharge";
        var obj = new Object();
        obj.procName = 'GetInvestmentPackages';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setPackages(res);
            setRechargeAmount(res[0].InvestmentAmount);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }
    const GetWalletAmount = () => {
        setIsLoading(true);
        var param = new Object();
        param.MemberId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.ActionMode = "GetWalletAmount";
        var obj = new Object();
        obj.procName = 'SenderRegistration';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setwalletAmount(res[0].ProductWallet);
            setToken(res[0].Token);
            setdefaultUPI(res[0].defaultUPI);
            setATCNumber(res[0].ATCNumber);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }
    const GetRechargeAmount = (amount) => {
        setRechargeAmount(amount);
    }

    const Deposit = () => {
        if (rechargeAmount < 500 || rechargeAmount > 20000) {
            toast.error("Minimum Recharge is ₹500 Maximum Recharge Amount is ₹20000");
            return;
        }
        if (parseFloat(rechargeAmount) > 0) {
            var url = "upi://pay?pa=" + defaultUPI + "&pn=BUSINESS STORE&tr=" + ATCNumber + "&am=" + rechargeAmount + "&cu=INR&mc=5094";
            window.location.href = url;
            console.log(url);

        } else {
            toast.error("Please Enter Recharge Amount");
        }
    }

    const Proceed = () => {
        if ((parseFloat(rechargeAmount) || 0) == 0) {
            toast.error("Enter Recharge  Amount");
            return;
        }
        setIsLoading(true);
        var param = new Object();
        param.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.Amount = rechargeAmount;
        axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + 'DepositNow',
            data: param
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            //console.log(res);
            window.location.href = res;
        }).catch(function (error) {
            setIsLoading(false);
        });
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Recharge</title>
                <link rel="stylesheet" href="css/newrecharge.css" />
                <link rel="stylesheet" href="css/font-awesome.min.css" />
            </Helmet>
            <div>
                <div><Toaster toastOptions={{
                    // Define default options
                    position: "top-center",
                    className: '',
                    duration: 5000,
                    style: {
                        fontSize: '0.28em',
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff'
                    }
                }} /></div>
                {isLoading ? <Loading /> : ""}
                <div className="recharge-wrap">
                    <div className="navbar">
                        <BackButton path='home' />
                        <span style={{ marginRight: '25%', marginTop: '-15px' }}>Recharge</span>

                    </div>
                    <div className="list-wrap" style={{ marginTop: 3, borderRadius: 0 }}>
                    <div className='BalanceAmt'>
                        <div><h5 style={{ fontSize: '1.2em', marginBottom: '0px' }}>Deposit Balance</h5> 
                        <div id="BalanceWithdrawnAmount" style={{ fontWeight: 'bold',color:'blue' }} /><i className='fa fa-inr'></i>{walletAmount}</div>
                    </div>
                        <p style={{ margin: 0, fontSize: 16, color: '#000',fontStyle:'italic' }}>Your Recharge Amount:</p>
                        <div className="hwrap">
                            <i className="fa fa-inr" style={{ fontSize: 17, marginTop: 15, color: '#fff' }} /><input style={{ fontSize: 18 }} value={rechargeAmount} className="money" onChange={handleChange} ref={inputRefAmount} placeholder="Amount" inputMode="decimal" id="txtDepositAmount" type="tel" maxLength={8} disabled/>
                        </div>
                        
                        <RechargePackages data={packages} ramount={rechargeAmount} func={GetRechargeAmount} />
                        

                        <div className="btn btn-info" style={{marginTop:'-22%',backgroundColor:'#000'}} onClick={Proceed}>Recharge Now</div>
                        <p className="tips" style={{ color: '#000', marginBottom: 0, marginTop: '-9px', fontStyle: 'italic' }}>
                            Note:In case your recharge balance is not reflected in 1 minute feel free to contact with our support team with transaction screenshot.
                        </p>
                        <div hidden>
                            <hr style={{ borderTop: '1px dashed #C38B60', marginBottom: 0, marginTop: 0 }} />
                            <div>
                                <p style={{ fontSize: 12 }}>Note: Please Enter UTR &amp; Amount to confirm your recharge once confimed
                                    your recharge amount will be added to your wallet.</p>
                                <p style={{ margin: 0, fontSize: 14, color: '#000' }}>Enter UTR No.:</p>
                                <div className="hwrap">
                                    <input className="money" onChange={handleUTRChange} ref={inputRefUTR} name="UTRNo" placeholder="Enter UTR No." id="UTRNo" type="text" />
                                </div>
                                <p style={{ margin: 0, fontSize: 14, color: '#000' }}>Enter Amount:</p>
                                <div className="hwrap">
                                    <input className="money" onChange={handleTxnAmountChange} ref={inputRefTxnAmount} name='TxnAmount' placeholder="Enter Amount" id="TxnAmount" type="number" />
                                </div>
                                <div className="btn btn-info" style={{ backgroundColor: '#28a745' }}>Confirm Recharge</div>
                                <p style={{ fontSize: 12, fontStyle: 'italic' }}>Instructions: Please see below image to find UTR on your UPI App.</p>
                                <center> <img style={{ height: 100 }} src="img/UTR1.jpeg" /></center>
                                <center><img style={{ height: 100 }} src="img/UTR2.jpeg" /></center>
                            </div>
                        </div>

                    </div>
                    <div id="SoonPopup">
                        <div className="mask-body" onclick="stopPropagation()" style={{ background: '#fff', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                            <div style={{ backgroundColor: '#a7c1f9', opacity: '0.7', height: '100%', borderRadius: 8, padding: 5 }}>
                                <div style={{ margin: 0, padding: 10, textAlign: 'right' }}>
                                    <p style={{ margin: 0, fontSize: 16 }}>
                                        <a href="javascript:void(0)" id="close" style={{ textDecoration: 'none' }} onclick="maskClick()"><span style={{ border: '1px solid #f23e36', padding: '3px 6px', borderRadius: 50, color: '#f23e36' }}>X</span></a>
                                    </p>
                                </div>
                                <div className="notify" style={{ textAlign: 'center' }}>
                                    <span id="ticketAmount" style={{ fontSize: '16px!important' }} />
                                    <h6 style={{ marginBottom: 18, fontSize: '16px!important' }}>Please accept the payment request on
                                        your UPI app</h6>
                                    <span className="mb-0 " style={{ color: 'red', background: '#fff', padding: '6px 10px', borderRadius: 50, fontSize: 15 }}>Timer:
                                        <span className="countdown" style={{ color: 'red' }} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }
                    .list-wrap .btn {
                        height: 0.88rem;
                        line-height: 44px;
                        background: #4D148C;
                        border-radius: 4px;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 400;
                        margin: 0.6rem 0 0.7rem 0;
                        cursor: pointer;
                        opacity: 1;
                        pointer-events: all;
                        width: 100%;
                        font-family: Microsoft YaHei !important;
                    }
            
                    /* Firefox */
                    input[type=number] {
                      -moz-appearance: textfield;
                    }
                    .list-wrap ul li:nth-child(4n) {
                        margin-right: 10px;
                    }
        
                    .bgSelect img {
                        position: relative !important;
                        top: 4px !important;
                        filter: invert(1);
                        height: 15px !important;
                        display: none;
                    }
        
                    .mask-tips>div .text p {
                        text-align: center;
                        font-size: .24rem;
                        line-height: .39rem;
                        color: #ffa400;
                        font-weight: 400;
                        margin: 1px;
                    }
                    .money {
                        font-size: .666667rem;
                        font-family: PingFang;
                        font-weight: 800;
                        color: #C38B60;
                        display: inline-block;
                        margin-right: .4rem;
                    }
                    .list-wrap .hwrap input {
                        height: 100%;
                        width: 100%;
                        height: 48px;
                        border: none;
                        font-size: .32rem;
                        font-weight: 500;
                        text-align: center;
                        color: #fff!important;
                    }
        
                    .list-wrap .hwrap {
                        width: 6.98rem;
                        height: 49px;
                        background: #212529 !important;
                        border-radius: 0.2rem;
                        margin-top: 13px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        margin-bottom: 0.4rem;
                        padding-left: 10px;
                    }
        
                    .hwrapnew input {
                        width: 100%;
                        border-radius: 4px;
                        font-size: 21px;
                        background: aliceblue;
                        border: none;
                        margin-top: 5px;
                        margin-left: 0;
                        border: none;
                        height: .8rem;
                        border-radius: .133333rem;
                        background: rgba(67, 117, 252, .1);
                        padding-left: .4rem;
                        font-weight: 700;
                    }
                    #SoonPopup {
                        position: fixed;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 40;
                        background: rgba(0, 0, 0, 0.4);
                        display: none;
                    }
        
                    #SoonPopup .mask-body {
                        background: #FFFFFF;
                        -webkit-box-shadow: 0px 0px 0.2rem rgb(0 0 0 / 16%);
                        box-shadow: 0px 0px 0.2rem rgb(0 0 0 / 16%);
                        border-radius: 0.2rem;
                        width: 6.9rem;
                        height: 4.96rem;
                        position: fixed;
                        left: 50%;
                        top: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                    }
        
        
                    .list-wrap ul {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        margin-top: 0.3rem;
                        padding-left: 0px;
                    }
        
                    .list-wrap ul li {
                        background: #ffffff;
                        border-radius: 11px;
                        text-align: center;
                        line-height: .8rem;
                        font-size: .28rem;
                        font-weight: normal;
                        color: #000;
                        margin-right: 0.22rem;
                        margin-bottom: 0.2rem;
                        list-style: none;
                        cursor: pointer;
                        border: .013333rem solid #bbb;
                        width: 25%;
                        height: 0.8rem;
                    }
        
                    .list-wrap ul {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        margin-top: 0.3rem;
                        padding: 0px;
                        justify-content: center;
                    }
        
                    .list-wrap .btn {
                        height: 0.88rem;
                        line-height: .88rem;
                        background: #FF6600;
                        border-radius: 0.44rem;
                        text-align: center;
                        color: #fff;
                        font-size: .36rem;
                        font-weight: 400;
                        margin: 0.6 rem 0 0.7rem 0;
                        cursor: pointer;
                        opacity: 1;
                        width: 100%;
                    }
        
                    .selectmoney {
                        display: grid;
                        grid-template-columns: 4fr 4fr 4fr;
                        padding: 10px;
                    }
        
                    .selectdata {
                        display: flex;
                        background: #ffffff;
                        padding: 0px 0px;
                        border-radius: 8px;
                        margin: 4px;
                        justify-content: center;
                        position: relative;
                        border: 1px solid #998e8e;
                    }
        
                    .selectdataimg img {
                        filter: invert(1) !important;
                        height: 15px !important;
                    }
        
                    .selecttext p {
                        margin: 0px;
                        font-size: 18px;
                        color: #998e8e !important;
                    }
        
                    .selectdata.active {
                        background-color: #ffffff;
                    }
        
                    .selectdata.active:after {
                        content: "\f058";
                        position: absolute;
                        left: 76px;
                        font-size: 21px;
                        color: #f23e36;
                        font-family: 'FontAwesome';
                    }
        
                    .list-wrap .hwrap input {
                        height: 100%;
                        width: 100%;
                        height: 48px;
                        border: none;
                        background: transparent;
                        font-size: .32rem;
                        font-weight: 500;
                        text-align: left !important;
                        color: #333;
                    }
        
                    .list-wrap .hwrap {
                        width: 6.98rem;
                        height: 49px;
                        background: #F6F6F6;
                        border-radius: 0.2rem;
                        margin-top: 13px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        margin-bottom: 0.4rem;
                    }
        
                    .save-btn {
                        background: #4D148C !important;
                        color: #fff;
                        margin: 12px;
                        height: 40px;
                        border-radius: 5px;
                        text-align: center;
                        line-height: 40px;
                        margin-top: 33px;
                        width: 90%;
                        font-size: 20px;
                        font-family: Microsoft YaHei !important;
                    }
        
                    .list-wrap .tips {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: .45rem;
                        color: #999999;
                        margin-top: 0.1rem;
                        color: purple;
                    }
          `
                }}
            />
        </>
    )
}
