import React from 'react'
export default function RechargePackages(props) {
    return (
        <ul>
            {props.data.map((item, i) => {
                const PackageList = (
                    <li className="bgSelect" style={props.ramount == item.InvestmentAmount ? { backgroundColor: "#2fcc58", color: "#fff" } : {}} onClick={() => props.func(item.InvestmentAmount)} key={i}><i className="fa fa-inr"></i>{item.InvestmentAmount}</li>
                );
                return PackageList;
            })}
        </ul>
    );
}
