import React from 'react'
import { useNavigate } from "react-router-dom";
export default function BackButton(props) {
    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    return (
        <div>
            <div className='col-12'>
                <div className='row'>
                    <div class="navbar navbar2">

                        <span style={{ marginRight: '25%', marginTop: '-15px' }}>About Us</span>
                        <span>
                        </span>
                    </div>
                    <div className='col-12' style={{minHeight:'110vh'}}>

                        <div>
                            <center> <img src="img/logo.png" style={{ width: '100%', marginBottom: 2 }} alt /></center>
                        </div>
                        <p style={{ fontSize: '0.26em', marginBottom: '10px', color: '#000' }}>
                        TotalEnergies is a global multi-energy company that produces and markets energies: oil and biofuels, natural gas and green gases, renewables and electricity. Our more than 100,000 employees are committed to energy that is ever more affordable, cleaner, more reliable and accessible to as many people as possible. Active in more than 130 countries, TotalEnergies puts sustainable development in all its dimensions at the heart of its projects and operations to contribute to the well-being of people
                        </p>
                        <h5 style={{ fontSize: 15 }}>Out Vision</h5>
                        <p style={{ fontSize: '0.26em',marginTop:-25, marginBottom: '10px', color: '#000' }}>TotalEnergies' history can be defined by three keywords: rich, unique and exhilarating. The Company's history tells of an industrial saga and a human endeavour set against the backdrop of social, technological and geopolitical changes. Its history also relates the story of a company that was created from scratch back in 1924 under the name Compagnie française des pétroles with the aim of ensuring France's energy independence. Over the years, it has evolved into an international organization that is actively involved in addressing the global energy challenges of the 21st century.</p>
                        <h5 style={{ fontSize: 15 }}>Out Approch</h5>
                        <p style={{ fontSize: '0.26em',marginTop:-25, marginBottom: '10px', color: '#000' }}>We rely on our values to guide our actions and relationships with our stakeholders, taking a collective approach to achieve our ambition of being a world-class player in the energy transition.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
