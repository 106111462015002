import React, { useState, useRef } from 'react'
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Loading from './Loading';
import toast, { Toaster } from 'react-hot-toast';
import { Helmet } from "react-helmet";
export default function Auth() {
    let navigate = useNavigate(); // For Navigation Reference
    const inputRefMobile = useRef(); //For Mobile Focus Reference
    const inputRefPassword = useRef();//For Password Focus Reference

    //State Management
    const [MobileNo, setMobileNo] = useState('');
    const [Password, setPassword] = useState('');

    //Redirection Method
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };

    //Handling Input Change
    const handleChange = (event) => {
        if (event.target.name == "txtMobileNo") {
            setMobileNo(event.target.value);
        } else {
            setPassword(event.target.value);
        }
    }
    //Handling Loading 
    const [isLoading, setIsLoading] = useState(false);

    //Ajax Calling For Login
    const doLogin = () => {
        if (MobileNo == "") {
            toast.error('Enter Mobile No.')
            inputRefMobile.current.focus();
            return;
        }
        if (MobileNo.toString().length < 10 || MobileNo.toString().length > 15) {
            toast.error('Enter Valid Mobile No.');
            inputRefMobile.current.focus();
            return;
        }
        if (Password == "") {
            toast.error('Enter Password.');
            inputRefPassword.current.focus();
            return;
        }
        setIsLoading(true);
        var obj = {};
        obj = new Object();
        obj.UserId = MobileNo;
        obj.Password = Password;
        var param = new Object();
        param.procName = "SolidityLogin"
        param.para = JSON.stringify(obj);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: param
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            if (res[0].StatusCode == "1") {
                toast.success('Logged-In Successfully...');
                var UserDetails = JSON.stringify(res[0]);
                UserDetails = UserDetails.hexEncode();
                localStorage.setItem("UserDetails", UserDetails);
                setTimeout(function () {
                    routeChange('home');
                }, 1000)

            } else {
                toast.error(res[0].msg);
            }
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }
    const [passwordType, setPasswordType] = useState("password");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    return (
        <>
            <div><Toaster toastOptions={{
                // Define default options
                position: "top-center",
                className: '',
                duration: 5000,
                style: {
                    fontSize: '0.28em',
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff'
                }
            }} /></div>
            {isLoading ? <Loading /> : ""}
            {/* <Helmet>
                <link rel="stylesheet" href="css/auth.css?v=1"/>
                
            </Helmet> */}
            <div className="form">
                <img src='img/logo.png?v=1' className='logo' />
                <div className="input">
                    <div className="inputBox">
                        <label htmlFor>Mobile No.</label>
                        <input id="txtMobileNo" ref={inputRefMobile} onChange={handleChange} type="text" name="txtMobileNo" placeholder='Enter Mobile No.' />
                    </div>
                    <div className="inputBox">
                        <label htmlFor>Password</label>
                        <input id="txtPassword" ref={inputRefPassword} onChange={handleChange} type={passwordType} name="txtPassword" placeholder='Enter Password' />
                    </div>
                    <div className="inputBox">
                        <input type="button" className='loginButton' name defaultValue="Sign In" onClick={doLogin} />
                    </div>
                </div>
                <p className="forgot">Don't have an account? <a href="#" onClick={() => routeChange("signup")}>Click Here</a></p>
                <div className="social" style={{ marginTop: '-28px' }}>
                    <button onClick={() => window.open('https://t.me/totalenergyofficial')}><i style={{ fontSize: 30, color: 'rgb(42 104 230)', background: '#fff' }} className="fa fa-telegram" aria-hidden="true" /><p style={{ marginBottom: 0 }}>Official Telegram</p></button>
                    <button onClick={() => window.open('https://t.me/Totalenergies01')}><i style={{ fontSize: 30, color: 'rgb(42 104 230)' }} className="fa fa-user" aria-hidden="true" /><p style={{ marginBottom: 0 }}>Contact Support</p></button>
                    {/* <button><a href='wealthmax.apk'><i style={{ fontSize: 20,color:'green' }} className="fa fa-android" aria-hidden="true" /><p className='app' style={{ marginBottom: 0 }}>Download App</p></a></button> */}
                </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    /* @import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap'); */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Oswald', sans-serif;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #060c21;
}

.form {
    position: relative;
    background: #060c21;
    border: 1px solid #ffc107;
    width: 350px;
    padding: 0px 40px 60px;
    border-radius: 10px;
    text-align: center;
}

.form::before {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    background: linear-gradient(315deg, #EE1A3B, #EE1A3B);
    z-index: -1;
    transform: skew(2deg, 1deg);
    border-radius: 10px;
}

.form h2 {
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.form .input {
    margin-top: 40px;
    text-align: left;
}

.form .input .inputBox {
    margin-top: 10px;
}

.form .input .inputBox label {
    display: block;
    color: #fff;
    margin-bottom: -31px;
    font-size: 18px;
    letter-spacing: 1px;
}

.form .input .inputBox input {
    position: relative;
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    padding: 5px 15px;
    background: linear-gradient(315deg, #fff, #fff);
    color: #000;
    font-size: 18px;
    border-radius: 10px;
}

.form .input .inputBox input[type="submit"] {
    cursor: pointer;
    margin-top: 20px;
    letter-spacing: 1px;
}

.form .input .inputBox input[type="submit"]:hover {
    background: linear-gradient(315deg, #f4a908, #71A834);
}

.form .input .inputBox input[type="submit"]:active {
    color: rgba(255, 255, 255, 0.521);
    background: linear-gradient(315deg, #e91e6271, #5f02ff8c);
}

.forgot {
    margin-top: 10px;
    color: #fff;
    font-size: 14px;
    letter-spacing: 1px;
}

.forgot a {
    color: #fff;
}

.social {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.social button {
    width: 75%;
    border-radius: 20px;
    margin-bottom: 15px;
    padding: 5px 10px;
    background: transparent;
    border: 1px solid #dee2e6;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;

}

.social button i {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #fff; */
    width: 20px;
    height: 20px;
    border-radius: 50%;

}

.social button p {
    color: #fff;
    font-size: 15px;
    margin-left: 10px;
    letter-spacing: 1px;
}

.social button:hover {
    background: linear-gradient(315deg, #326cd6, #5d02ff);
}

.social button:hover i {
    filter: invert(1);
}

.social button:active {
    background: linear-gradient(315deg, #f4a908, #71A834)
}
                    .app{
                        margin-bottom: 0px!important;
                        position: absolute!important;
                        margin-left: 9%!important;
                        margin-top: -22px!important;
                    }
                    ::placeholder {
                        color: #000 !important;
                    }
                    .logo {
                        width: 235px;
                        height:120px;
                        margin: auto;
                       
                    }
                    .loginButton{
                        background:#EE1A3B!important;
                        color:#fff!important;
                    }
          `
                }}
            />
        </>
    )
}
