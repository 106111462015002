import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import { useNavigate, Link } from "react-router-dom";
import Tabs from 'react-bootstrap/Tabs';
import axios from "axios";
import TabData from './TabData';
import Loading from './Loading';
import toast, { Toaster } from 'react-hot-toast';
import TabDataFlexible from './TabDataFlexible';
function ControlledTabsExample() {
  const [key, setKey] = useState('VIPProducts');
  const [packagesVIP, setVIP] = useState([]);
  const [packagesFixed, setFixed] = useState([]);
  const [packagesEvent, setEvent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPopup, setPopup] = useState(false);
  const [packageId, setPackageId] = useState(0);
  //Handling Loading 
  const [investmentAmount, setInvestmentAmount] = useState(0);
  const [packageDetail, setPackageDetail] = useState({
    packageName: "",
    packageAmount: ""
  });
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate('../' + path, { replace: true });
  };
  useEffect(() => {
    BindPackage();
  }, []);
  const BindPackage = () => {
    setIsLoading(true);
    var param = new Object();
    param.ActionMode = "GetMyPackages";
    var obj = new Object();
    obj.procName = 'GetInvestmentPackages';
    obj.Para = JSON.stringify(param);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_WEBAPI_URL,
      data: obj
    }).then(function (res) {
      setIsLoading(false);
      res = res.data;
      setVIP(JSON.parse(res[0].VIP));
      setFixed(JSON.parse(res[0].Fixed));
      setEvent(JSON.parse(res[0].Event));
    }).catch(function (error) {
      setIsLoading(false);
      console.log(error);
    });
  }
  const ProceedToBuy = (pId, pName, pAmount) => {
    setPackageDetail({ packageName: pName, packageAmount: pAmount })
    if (pAmount == 0) {
      toast.error("Please enter amount");
      return;
    }
    setPopup(true);
    setPackageId(pId);
  }
  const InvestNow = () => {
    setIsLoading(true);
    var param = new Object();
    param.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
    param.PackageAmount = packageDetail.packageAmount;
    param.PackageId = packageId;
    //console.log(param);
    //return;
    var obj = new Object();
    obj.procName = 'MemberInvestment';
    obj.Para = JSON.stringify(param);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_WEBAPI_URL,
      data: obj
    }).then(function (res) {
      setIsLoading(false);
      res = res.data;
      if (res[0].StatusCode == "0") {
        setPopup(false);
        toast.error(res[0].Msg);
        setTimeout(() => {
          routeChange('RechargeWallet')
        }, 1000);
      }
      else if (res[0].StatusCode == "2") {
        toast.error(res[0].Msg);
        setPopup(false);
      }
      else {
        setPopup(false);
        toast.success(res[0].Msg);
        //party.confetti(e);
      }
    }).catch(function (error) {
      setIsLoading(false);
    });
  }
  return (
    <>
      <div><Toaster toastOptions={{
        position: "top-center",
        className: '',
        duration: 5000,
        style: {
          fontSize: '0.28em',
          borderRadius: '10px',
          background: '#333',
          color: '#fff'
        }
      }} /></div>
      {isLoading ? <Loading /> : ""}
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-1"
      >
        <Tab eventKey="VIPProducts" title="Total Energies">
          <TabDataFlexible data={packagesVIP} func={ProceedToBuy} />
        </Tab>
        <Tab eventKey="FlexibleProducts" title="Exclusive">
          <TabDataFlexible data={packagesFixed} func={ProceedToBuy} />
        </Tab>

        <Tab eventKey="EventProducts" title="Mega Exclusive">
          <TabDataFlexible data={packagesEvent} func={ProceedToBuy} />
        </Tab>
      </Tabs>
      {isShowPopup ?
        <div id="mask">

          <div className="mask-body" onclick="stopPropagation()" style={{ height: 160, borderRadius: 10, background: '#000c' }}>
            <div style={{ position: 'relative', background: 'transparent', height: 'auto' }}>

            </div>
            <div style={{ background: 'transparent', color: '#fff', textAlign: 'center', width: '100%', marginTop: '15px' }}>
              Are you sure?
            </div>
            {/* <div style={{ background: 'transparent', color: '#918989', textAlign: 'center', width: '100%', fontSize: '14px', marginTop: '-15px' }}>
              Confirm Purchase?
            </div> */}
            <div style={{ position: 'fixed', bottom: '5px', padding: '10px', width: '100%', borderTop: 'solid 1px #ddd', lineHeight: '20px', borderRadius: '0px' }}>
              <div style={{ float: 'left', color: 'red', padding: '5px' }} onClick={() => setPopup(false)}>Cancel</div>
              <div style={{ float: 'right', color: 'green', padding: '5px' }} onClick={InvestNow}>Confirm</div>
            </div>

          </div>

        </div>
        : null
      }
    </>
  );
}

export default ControlledTabsExample;